import React from 'react';

import addHighlightBold700 from '@src/utils/addHighlightBold700';
import addHighlightBold from '@src/utils/addHighlightBold';
import addHighlight from '@src/utils/addHighlight';

import { HeaderMappingTypes, SectionHeaderPropTypes } from './SectionHeader.types';

const SectionHeader: React.FC<SectionHeaderPropTypes> = ({
  pretitle,
  id,
  title,
  className = '',
  preTitleClassName = '',
  childrenClassName = '',
  align,
  children,
  pretitleRef,
  titleRef,
  slideTextRef,
  paragraphRef,
  titleHighlightBold,
  titleClassName = '',
  headingLevel = 'h2',
}) => {
  const HeadingTag = HeaderMappingTypes[headingLevel];

  // Function to get alignment classes
  const getAlignmentClasses = (alignment?: string) => {
    switch (alignment) {
      case 'center':
        return 'items-center justify-center text-center';
      case 'left':
        return '';
      // Add more cases here if needed
      default:
        return '';
    }
  };

  if (slideTextRef) {
    titleRef = slideTextRef;
  }

  return (
    <header id={id} className={`flex flex-col ${className} ${getAlignmentClasses(align)}`}>
      {pretitle && (
        <HeadingTag
          ref={pretitleRef}
          className={`mb-[1.6rem] text-[1.4rem] font-[400] uppercase leading-[2.4rem] tracking-[0.4px] text-primary ${preTitleClassName}`}
          dangerouslySetInnerHTML={{
            __html: addHighlightBold700(pretitle),
          }}
        />
      )}

      {title && (
        <HeadingTag
          ref={titleRef}
          className={`text-balance text-[2.4rem] leading-[3.2rem] ${titleClassName}`}
          dangerouslySetInnerHTML={{
            __html: titleHighlightBold ? addHighlightBold(title) : addHighlight(title),
          }}
        />
      )}

      {children && (
        <div
          ref={paragraphRef}
          className={`max-w-none text-balance text-[1.6rem] xl:max-w-[73.7rem] ${childrenClassName}`}
        >
          {children}
        </div>
      )}
    </header>
  );
};

export default SectionHeader;
