import { AddHighlightTypes } from './utils.types';

const addHighlight = (string: AddHighlightTypes) => {
  const regexHighlight = string?.replace(
    /\[(.*?)\]/g,
    '<span style="color: #ff7a00; font-weight: 500;">$1</span>'
  );
  return regexHighlight;
};

export default addHighlight;
