import React from 'react';

import useGsapLoaded from './useGsapLoaded';

import { SlideTextRefTypes } from './hooksTypes';

/**
 * Custom hook for applying scroll animations to section headers.
 * @returns {Object} Object containing the element references for pretitle, title, and paragraph.
 */
export default function useSlideTextAnimation(): SlideTextRefTypes {
  const slideTextRef = React.useRef(null); // Reference to the pretitle element
  const refElements = [slideTextRef]; // Array of element references
  const isGsapLoaded = useGsapLoaded();

  React.useEffect(() => {
    if (!isGsapLoaded) return;

    /**
     * Apply animation to an element using GSAP scrollTrigger.
     * @param {React.MutableRefObject} ref - Reference to the element.
     */
    const animateElement = (ref: any) => {
      const animationTimeline = window?.gsap?.timeline({
        repeat: -1,
        scrollTrigger: {
          trigger: ref.current, // Element used as the trigger for the animation
          start: `top bottom`, // Start position for the animation
          toggleActions: 'play pause restart restart',
        },
      });

      if (ref.current) {
        const children = Array.from(ref?.current?.children);

        children.forEach((element: any, index: number) => {
          // Apply white-space: nowrap to each child element
          window?.gsap?.set(element, {
            whiteSpace: 'nowrap',
          });

          if (index === children.length - 1) {
            animationTimeline
              .fromTo(
                element,
                {
                  opacity: 0,
                  y: -50,
                },
                {
                  y: 0,
                  opacity: 1,
                  duration: 1.5,
                  ease: 'elastic.out(1,0.6)',
                }
              )
              .to(element, {
                opacity: 1,
                duration: 1.5,
              })
              .to(element, {
                opacity: 0,
              });
          } else {
            animationTimeline
              .fromTo(
                element,
                {
                  opacity: 0,
                  y: -50,
                },
                {
                  y: 0,
                  opacity: 1,
                  duration: 1.5,
                  ease: 'elastic.out(1,0.6)',
                }
              )
              .to(element, {
                opacity: 0,
              });
          }
        });
      }
    };

    // Loop through each element reference and apply the animation
    refElements.forEach(animateElement);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGsapLoaded]);

  // Return the element references
  return { slideTextRef };
}
