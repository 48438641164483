'use client';
import React from 'react';

import useSlideTextAnimation from '@src/hooks/useSlideTextAnimation';
import Container from '@src/components/Shared/Container';
import NextImage from '@src/components/Shared/NextImage';
import SectionHeader from '@src/components/Shared/SectionHeader';

import { ImageTextPrimaryPropTypes } from './ImageTextSecondary.types';

const ImageTextSecondary: React.FC<ImageTextPrimaryPropTypes> = ({
  id,
  className,
  pretitle,
  title,
  imgURL,
  imgAlt = '',
  background = '',
  childrenElements,
  reversed = false,
  objectFit = 'cover',
  imgChildElements = null,
  titleClassName = '',
  // Passing additional Classes via Props for more flexibility
  imgFirstClassName = '',
  imgSecondClassName = '',
  textContainerClassName = '',
  sectionHeaderClassName = '',
  slide,
}) => {
  const { slideTextRef } = useSlideTextAnimation();

  return (
    <Container
      id={id}
      className={`flex flex-col gap-[4.2rem] overflow-y-hidden lg:flex-row lg:items-center lg:gap-[6.4rem] ${className}`}
      background={background}
    >
      <NextImage
        className={`lg:mb-[unset] lg:hidden ${imgFirstClassName}`}
        src={imgURL}
        alt={imgAlt}
        aspectRatio={16 / 9}
        priority
        width={100}
        objectFit={objectFit}
      >
        {imgChildElements}
      </NextImage>

      <NextImage
        className={`hidden lg:relative lg:z-[-5] lg:block lg:w-[50%] ${
          reversed ? 'lg:order-[10]' : 'lg:order-[-10]'
        } ${imgSecondClassName}`}
        src={imgURL}
        alt={imgAlt}
        objectFit={objectFit}
      >
        {imgChildElements}
      </NextImage>

      <div className={`lg:relative lg:z-[20] lg:w-[50%] lg:self-center ${textContainerClassName}`}>
        <SectionHeader
          className={`lg:relative lg:z-[5] lg:py-[4rem] ${sectionHeaderClassName}`}
          pretitle={pretitle}
          preTitleClassName='mb-[1.6rem] font-[400]'
          title={title}
          titleClassName={`text-[2.8rem] mb-[1.8rem] lg:mb-[3.2rem] lg:text-[4.8rem] lg:leading-[5.2rem] lg:tracking-[-0.096rem] ${titleClassName}`}
          childrenClassName='relative'
          align='left'
          {...(slide === true ? { slideTextRef } : {})}
        >
          {childrenElements}
        </SectionHeader>
      </div>
    </Container>
  );
};

export default ImageTextSecondary;
